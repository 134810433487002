const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';
const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
const SET_THEME = 'SET_THEME';
const SET_NAVIGATION_DATA = 'SET_NAVIGATION_DATA';
const TOGGLE_MENU = 'TOGGLE_MENU';
const SET_USER_DATA = 'SET_USER_DATA';

export {
    SHOW_LOADING,
    HIDE_LOADING,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    SET_THEME,
    SET_NAVIGATION_DATA,
    TOGGLE_MENU,
    SET_USER_DATA
}
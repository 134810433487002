// export const CONFIG = {
//     Title: 'CLIENT1_R',
//     product: 'CLIENT1_R',
// }

// export const CONFIG = {
//     Title: 'CLIENT2_B',
//     product: 'CLIENT2_B',
// }

export const CONFIG = {
    Title: 'Axiom',
    product: 'CLIENT3_A',
}

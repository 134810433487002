import React, { lazy } from "react";
import { Route, Redirect, Switch, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../components/Loading";
import Notification from "../components/Notification";
import SuspenseWrapper from "../components/SuspenseWrapper";
import { CONFIG } from "../constants/configuration";
import client1Icon from '../assets/icons/A_logo.png';
import client2Icon from '../assets/icons/client-2.jpg'
import client3icon from "../assets/icons/client-3-Blue BG.svg"

// APP ROUTE COMPONENTS
const Dashboard = lazy(() => import("./dashboard"));
const Account = lazy(() => import("./user"));

const App = () => {
  const match = useRouteMatch();
  const showLoading = useSelector((state) => state.commonReducer.showLoading);

  let imgPath = document.getElementById("favIcon")
  let appTitle = document.getElementById("app_title")
  if (CONFIG.product === "CLIENT1_R") {
    imgPath.href = client1Icon
    appTitle.innerHTML = "CLIENT1_R Auth"
  }
  else if (CONFIG.product === "CLIENT2_B") {
    imgPath.href = client2Icon
    appTitle.innerHTML = "Auth"
  }
  else if (CONFIG.product === "CLIENT3_A") {
    imgPath.href = client3icon
    appTitle.innerHTML = "Axiom"
  }

  return (
    <>
      <Loading show={showLoading} />
      <Notification />
      <div style={{ opacity: showLoading ? 0.5 : 1 }}>
        <SuspenseWrapper>
          <Switch>
            <Route path={`${match.url}dashboard`} key="dashboard">
              <Dashboard />
            </Route>
            <Route path={`${match.url}user`} key="user">
              <Account />
            </Route>
            <Route exact path="/" key="login">
              <Redirect to={"/user/login"} />
            </Route>
          </Switch>
        </SuspenseWrapper>
      </div>
    </>
  );
};

export default App;
